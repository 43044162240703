<template>
  <MetaHead title="Log In | Handcrafted" />

  <AuthGate
    :allow-anon-only="true"
    :force-visible="submitting"
  >
    <FormWrapper
      id="user-form-wrapper"
      :border="true"
      :pad="true"
      :limit="true"
    >
      <template #heading>
        <h1>Log In</h1>
      </template>
      <LoginForm
        @submit="() => submitting = true"
        @done="() => submitting = false"
      />

      <template #footer>
        <h2>
          Don't have an account?
        </h2>
        <RouterLink :to="registerHref">
          Create an account
        </RouterLink>
      </template>
    </FormWrapper>
  </AuthGate>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { getDestination } from '~/common/path';
import { useAuthStore } from '~/store/auth';

const route = useRoute();
const authStore = useAuthStore();

const submitting = ref(false);

const apiBaseUrlStore = useApiBaseUrlStore();
const destination = getDestination(route)
  ? `?destination=${getDestination(route)}`
  : '';

const registerHref = `/register${destination}`;

if ('403' in route.query) {
  authStore?.logout();
}
</script>

<style lang="scss">
body:has(#user-form-wrapper) {
  background: var(--color--bg-dark--alt);

  .section--newsletter-signup {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 2em;
  margin-bottom: 2em;
  background: white;
}

.cols {
  display: flex;
  height: calc(100vh - 2.5em);

  &__col {
    &:first-child {
      display: flex;
      flex-flow: column;
      width: 30em;
      max-width: 100%;
      padding-right: 4em;
      margin-top: 7em;
    }

    &:last-child {
      background-color: var(--color--bg-dark);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

:deep(.btn) {
  margin-bottom: 0;
}
</style>
